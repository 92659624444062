import React from 'react';
import { Layout } from '../layouts';
/**
 * UI component to display maps.
 */
export class AppointmentPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      iFrameVisible: false
    };

    this.handleBtnClick = this.handleBtnClick.bind(this);
  }

  handleBtnClick(e) {
    if (window.innerWidth > 600) {
      e.preventDefault();
      this.setState({
        iFrameVisible: true
      });
    }
  }

  render() {
    return (
      <Layout location={this.props.location}>
        <div>
          <div className="content-wrap">
            <h1 className="content-h2">Termine</h1>
            {/* <p>
              Bitte vereinbaren Sie rechtzeitig einen Behandlungstermin.<br />
              Die Terminvergabe kann telefonisch während den Sprechstundenzeiten erfolgen.
            </p>
            <br />
            <p>
              Gerne können Sprechstunden-Termine auch online gebucht werden:
            </p>
            {this.state.iFrameVisible ? null :
              <a className="appointmentBtn" href="https://app.samedi.de/practices/7h5p3xdm755pkcc8/api/booking-widget" target="_blank" onClick={this.handleBtnClick}>Sprechstunden-Termin online buchen</a>
            } */}


<h2 className="content-h3">Sprechstundentermine</h2>
<p>
Bitte kommen Sie nicht unangemeldet in die Praxis, sondern vereinbaren telefonisch oder online einen Termin.


            <br />
      {this.state.iFrameVisible ? null :
              <a className="appointmentBtn" href="https://app.samedi.de/practices/7h5p3xdm755pkcc8/api/booking-widget" target="_blank" onClick={this.handleBtnClick}>Termin online buchen</a>
            }
            </p>



          {this.state.iFrameVisible ?
            <iframe name="samedi-widget" src="https://app.samedi.de/practices/7h5p3xdm755pkcc8/api/booking-widget?category_id=33079&event_type_id=102709&skin=iframe" frameBorder="0" cellSpacing="0" style={{
              width: '100%', minWidth: 600, height: '100%', minHeight: '1200px', marginTop: '3em'
            }}></iframe>
            : null}


          </div>


        </div>
      </Layout>
    );
  }

}


export default AppointmentPage
